import * as React from "react"
import { Link } from "gatsby"

interface articleListProps {
  posts: any[]
}

const ArticlesList = ({ posts }: articleListProps) => {
  return (
    <ol className="m-2 ml-6">
      {posts.map(post => {
        const title = post.frontmatter.title || post.fields.slug
        return (
          <li key={post.fields.slug}>
            <article
              className="post-list-item"
              itemScope
              itemType="http://schema.org/Article"
            >
              <header>
                <h3>
                  <Link to={`/articles${post.fields.slug}`} itemProp="url">
                    <span itemProp="headline">{title}</span>
                  </Link>
                </h3>
                <small className="text-charcoal">
                  <span className="font-bold mr-2">
                    {post.frontmatter.project} update on {post.frontmatter.date}
                  </span>
                  <span className="flex flex-wrap">
                  {post.frontmatter.tags.split(", ").map(tag => (
                    <span
                      key={[post.fields.slug, "-", tag].join("")}
                      className="text-white bg-ptGreen text-xs rounded-full py-1 px-2 m-1 capitalize"
                    >
                      {tag}
                    </span>
                  ))}
                  </span>
                </small>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: post.frontmatter.description || post.excerpt,
                  }}
                  itemProp="description"
                  className="leading-tight"
                />
              </section>
            </article>
          </li>
        )
      })}
    </ol>
  )
}

export default ArticlesList 
