import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout.tsx"
import heroimg from "../../images/nftix_temp_hero_img.jpg"
import sportsThumb from "../../images/sports_thumbnail.png"
import musicThumb from "../../images/music_thumbnail.png"
import minorThumb from "../../images/minorLeagues_thumbnail.png"
import smallVThumb from "../../images/smallVenue_thumbnail.png"
import copeThumb from "../../images/cope_thumbnail.png"
import rosenThumb from "../../images/rosen_thumbnail.png"
import bootlegThumb from "../../images/bootleg_thumbnail.png"
import faceRec from "../../images/facial-recognition.jpeg"
import FeedbackCard from "../../components/feedback_card"
import ArticlesList from "../../components/articles_list.tsx"
import AboutUs from "../../components/about_us.tsx"

const NftixProject = ({ data, location }) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title="Nftix">
      <div className="nftix-project-hero" />
      <div className="lg:w-1/2 mx-4 lg:mx-16 lg:ml-12 -mt-24">
        <div className="absolute top-80 right-36 hidden xl:block">
          <FeedbackCard location="projects/nftix" />
        </div>
        <h1>Total Experience Stadium Pass</h1>
        <p className="lead">
          Team Zero is exploring ways to use Web3 and blockchain technologies
          for Sports and Music. This concept looks to leverage blockchain to
          create an all-in-one in-stadium experience. The digital "ticket" will
          have many utilities while enjoying the event and has delightful value
          after the event is over. It allows artists/players/teams a direct,
          anonymous connection to true fans and the ability to drop rewards. It
          also provides control over ticket prices and opens avenues for future
          royalties.
        </p>
      </div>
      <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto mt-16 text-black">
        <h3>Overview</h3>
        <p className="mb-4">
          Technology in professional sports has changed the industry in
          incredible ways. It has revolutionized how athletes train, creating
          stronger, faster, and more agile athletes. It's brought new equipment
          to protect the athletes and make the games safer. Technology has also
          provided equipment that measures biomechanics and data analytics that
          creates predictive models of how a team can exploit an opponent's
          weakness or how a team might strengthen its own. The fans enjoy more
          games or shows with more viewing options through internet services in
          high-resolution screens from multiple camera angles for sports and
          music. Ticket purchasing is more convenient through digital channels,
          and fans can connect with their favorites players through social
          media. It has been a remarkable few decades. And the future of sports
          and music will only continue to improve. There are numerous projects
          in the works using technologies such as Augmented and Virtual Reality
          (AR/VR), Artificial Intelligence (AI), Robotics, and Wearables. This
          article will specifically talk about how Web3 and Blockchain
          technology will be used in the future of sports and music. We believe
          Web3 and Blockchain technology will create vast improvements in the
          following five key areas...
        </p>
        <h3>
          <a href="#parityx10">1. Parity x10</a>
        </h3>
        <p className="mb-4">
          Keeping the same features of Web2 that fans expect, but enhancing the
          ticketing, souvenir, fan engagement, and digital experience by 10x.
        </p>
        <h3>
          <a href="#unlocks">2. Unlocks</a>
        </h3>
        <p className="mb-4">
          Digital tickets will become more than just entry passes into an event.
          The ticket can also allow artists and sports teams to give delightful
          and surprising “easter eggs” to their fans.
        </p>
        <h3>
          <a href="#loyalty">3. Loyalty</a>
        </h3>
        <p className="mb-4">
          Blockchain and Web3 will give user data back to the user but still
          allow artists, athletes, and sports teams to gain insight and reward
          their true fans without paying an intermediary.
        </p>
        <h3>
          <a href="#collectible">4. Collectibles</a>
        </h3>
        <p className="mb-4">
          Digital collectibles in the form of NFTs have taken the world by storm
          in 2021. So it's only a matter of time before sports and music ditch
          the bobbleheads and adopt this new form of souvenir.
        </p>
        <h3>
          <a href="#revenue">5. New Revenue</a>
        </h3>
        <p className="mb-4">
          Blockchain and Web3 will give user data back to the user but still
          allow artists, athletes, and sports teams to gain insight and reward
          their true fans without paying an intermediary.
        </p>
      </div>
      <section id="parityx10">
        <div className="blueprint py-32">
          <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto">
            <h2 className="jumbo pb-8">Parity x10</h2>
            <p className="lead max-w-2xl">
              Keeping the same features of Web2 that fans expect, but enhancing
              this digital experience by 10x.
            </p>
          </div>
        </div>
        <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto mt-4">
          <p className="mb-4">
            Web2 has changed the way fans engage at events. The digital ticket
            has become a convenient way for fans to check ticket availability,
            pick what seats they prefer, and pay, all within a convenient flow.
            The fan can use their smartphone with the digital ticket on it to
            get into the event. Team and stadium apps give the fan access to
            important information. For instance, fans no longer have to buy
            overpriced programs to know team rosters or keep stat lines at
            baseball games. Digital maps are displayed in team and stadium apps
            to show where concessions and first aid are located. These apps have
            even become sophisticated enough to gauge how long the wait is to
            use the restroom. It can also suggest alternatives with shorter
            lines. Stadiums like Citizen Bank Park and food giant Aramark have
            experimented with cashless kiosks called{" "}
            <a
              href="https://www.qsrmagazine.com/menu-innovations/how-sports-stadiums-are-upping-their-foodservice-game"
              target="_blank"
              rel="noreferrer"
            >
              "Zoom Foods"
            </a>{" "}
            to allow fans to self-order online or through a kiosk and then pick
            up their food and drink at designated pick-up areas. Despite all
            these significant advances, there are still some areas that can be
            improved moving forward.{" "}
          </p>

          <p>
            For starters, digital tickets can still be fraudulent. They are
            indeed more secure than paper tickets, and company's like
            TicketMaster are developing ways to deter fraudsters. In 2019,
            TicketMaster developed{" "}
            <a
              href="https://blog.ticketmaster.com/introducing-safetix-ticketmasters-next-gen-encrypted-tickets/"
              target="_blank"
              rel="noreferrer"
            >
              "SafeTix,"
            </a>{" "}
            a function tied to the ticket holder's mobile device through an
            encrypted barcode that automatically refreshes every few seconds.
          </p>
          <img
            className="md:float-right mx-auto  py-4 md:p-8"
            src="https://s1.ticketm.net/en-us/img/static/securemobiletickets/img-step3@1x.gif"
            alt="TicketMaster's SafeTix"
          />
          <p>
            There's been a long love/hate relationship between bands/fans and
            TicketMaster. TicketMaster has had a lock on the multibillion-dollar
            ticketing industry and have only grown larger the last decade when
            they merged with Live Nation, the largest concert promoter in the
            United States. As a result, venues, artists, and teams have
            struggled to keep control of ticket prices. To make this situation
            worse, TicketMaster also owns a significant stake in the reseller
            ticket market, causing ticket prices to be even higher for fans, but
            does not benefit the artist, venue, or sports club. TicketMaster
            also has a reputation among fans for high "convenience fees" charged
            to the fan per ticket, not transaction. Plus, to buy a ticket, the
            fan must agree to a privacy policy that gives them the right to
            share their information with other companies.{" "}
          </p>
          <img
            className="w-full"
            src={faceRec}
            alt="TicketMaster is developing Facial Recognition tech to replace tickets"
          />
          <p className="pb-8">
            {" "}
            SafeTix is only the beginning of TicketMaster's exploration in fraud
            deterrent. They also invested in start-up{" "}
            <a
              href="https://www.blinkidentity.com/"
              target="_blank"
              rel="noreferrer"
            >
              Blink Identity
            </a>
            , which specializes in facial recognition technology. These
            innovations sound great for guaranteeing that entry tickets are
            authentic, but it sounds like it comes with many privacy risks.
            Amazon is also experimenting with its palm-scanning technology that
            it has used in{" "}
            <a
              href="https://www.engadget.com/amazons-just-walk-out-tech-comes-to-two-whole-foods-stores-101036042.html"
              target="_blank"
              rel="noreferrer"
            >
              Whole Foods
            </a>{" "}
            and its cashless stores. Amazon partnered with ticketing company{" "}
            <a href="https://www.axs.com/" target="_blank" rel="noreferrer">
              AXS
            </a>{" "}
            and{" "}
            <a
              href="https://www.engadget.com/amazon-one-sports-stadiums-music-venues-083245937.html"
              target="_blank"
              rel="noreferrer"
            >
              deployed standalone ticketing pedestals at Red Rocks Amphitheatre
              in Denver and are currently in use as of September 14, 2021
            </a>
            . It is a bit concerning that TicketMaster and Amazon, which has a
            substantial profile on consumers, will potentially become the
            central system of personal identification to go along with the
            stores of data they are obtaining on fans.{" "}
          </p>
          <h5 className="uppercase">
            Decentralization for Authentication and Privacy
          </h5>
          <p className="mb-4">
            Privacy and data ownership concerns are where{" "}
            <a
              href="https://decrypt.co/resources/what-is-web-3"
              target="_blank"
              rel="noreferrer"
            >
              Web3
            </a>{" "}
            and{" "}
            <a
              href="https://decrypt.co/resources/blockchain-basics-what-is-blockchain"
              target="_blank"
              rel="noreferrer"
            >
              blockchain
            </a>
            can help. Blockchain is inherently secure and is an excellent fraud
            deterrent. Each "ticket" is unique and cannot be replicated, and its
            authenticity and who owns it is easy to verify. No one owns the
            blockchain or Web3. It's simply a tool available to anyone and has
            millions of people transacting on it today. Transactions can be
            simple peer-to-peer payments like sending{" "}
            <a
              href="https://decrypt.co/resources/what-is-bitcoin-four-minute-instant-guide-explainer"
              target="_blank"
              rel="noreferrer"
            >
              Bitcoin
            </a>{" "}
            to a friend. Blockchains like{" "}
            <a
              href="https://decrypt.co/resources/what-is-ethereum-quickly-explained-four-minute-guide"
              target="_blank"
              rel="noreferrer"
            >
              Ethereum
            </a>{" "}
            contain{" "}
            <a
              href="https://decrypt.co/resources/smart-contracts"
              target="_blank"
              rel="noreferrer"
            >
              "smart contracts"
            </a>{" "}
            that can create rules, for example, set parameters between parties
            and release payment or exchanges when those parameters are met. So
            not only can a fan's "ticket" authenticate that they are the owner
            to allow entry into the event, but it is also built on top of
            payment rails that can be used to make other transactions during the
            event. The closest Web2 equivalent is a product called{" "}
            <a
              href="https://www.givex.com/products/uptix/"
              target="_blank"
              rel="noreferrer"
            >
              uptix
            </a>{" "}
            by{" "}
            <a href="https://www.givex.com/" target="_blank" rel="noreferrer">
              givex
            </a>
            . Uptix is a stadium ticket that permits entry and also allows
            venues to load money directly onto a fan's ticket. This cashless
            payment method can be used at stadium concessions. For instance,
            venues can also push real-time promotions—a $5 discount on
            concessions after a home run.
          </p>
          <p className="mb-16">
            Ethereum also has the ability to tie to your identity but in a more
            controlled and private way. For example, a fan could buy an
            alcoholic beverage without having to flash an ID. The purchase on
            Ethereum only check and authenticates that you are of age to make
            this purchase and gives the vendor no further details unlike using
            credit cards which track your spending habits. Tying identity to fan
            engagement can open up new possibilities in stadiums that are
            innovative to utilize{" "}
            <a
              href="https://decrypt.co/resources/wallets"
              target="_blank"
              rel="noreferrer"
            >
              Web3 wallets
            </a>{" "}
            and{" "}
            <a
              href="https://decrypt.co/resources/blockchain-transactions"
              target="_blank"
              rel="noreferrer"
            >
              blockchain public keys
            </a>
            . It will also allow fans to protect and control their personal data
            while still serving venues, teams, and artists access to the more
            relevant pieces.
          </p>
        </div>
      </section>
      <section id="unlocks">
        <div className="blueprint py-32">
          <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto">
            <h2 className="jumbo pb-8">Unlocks</h2>
            <p className="lead max-w-2xl">
              Digital tickets will become more than just entry passes into an
              event. The ticket can also allow artists and sports teams to give
              delightful and surprising “easter eggs” to their fans.
            </p>
          </div>
        </div>
        <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto mt-4">
          <p className="mb-8">
            The ability for Non-Fungible Tokens to create "unlocks" was a big
            inspiration for this concept. We imagine a world where fans can go
            to a concert of their favorite band, and after the show, their
            "ticket" alerts them that the band has unlocked a hidden asset in
            the fan's "ticket" to thank them for going to the show. The unlock
            can be a video of the first four songs played in the live
            performance the fan just attended. The fan can now relive this
            experience over and over again. The artist can set it up so that
            each venue section is given different songs from the setlist. In
            this example, the fan received the first four because they were in
            section A. As a fan of shows, I can see a market for fans that want
            the full 20-song setlist. This new marketplace opportunity would
            allow the fan to trade, buy, or sell their unlock. For performers
            cracking down on the use of smartphones in their shows, this could
            be a nice consolation prize to give social proof back to the fan.
          </p>
          <h5 className="uppercase">Our Experiments</h5>
          <div>
            <p>
              Team Zero along with teammates,{" "}
              <a href="https://www.serto.id/" target="_blank" rel="noreferrer">
                Serto
              </a>
              , ran an experiment on ticket unlocks and decentralized identity
              with{" "}
              <a
                href="https://citizencope.com/"
                target="_blank"
                rel="noreferrer"
              >
                Citizen Cope
              </a>
              . Here is the use case and result.
            </p>
            <a href="../cope">
              {/* Card ONE */}
              <div className="md:flex rounded overflow-hidden shadow-lg my-4 bg-white mb-16">
                <img
                  className="flex-auto w-full overflow-hidden"
                  src={copeThumb}
                  alt="Citizen Cope NFT"
                />
                <div className="px-6 py-4 flex-wrap">
                  <div className="font-bold text-xl mb-2">
                    Citizen Cope Drop
                  </div>
                  <p className="text-gray-700 text-base">
                    First ever NFT concert ticket for a Citizen Cope concert in
                    NYC. NFT holder also gets exclusive content access.
                  </p>
                </div>
              </div>
            </a>
            <p className="pb-16">
              We're still exploring industries and problems, researching,
              contacting people in these industries to perform small
              experiments. If you are interested in learning how you might work
              with us or explore future tech for your industry, please reach out
              to us by using <a href="#form">the form on this page</a> or say
              "hi" on Twitter{" "}
              <a
                href="https://twitter.com/TeamZeroDev"
                target="_blank"
                rel="noreferrer"
              >
                @team-zero-dev
              </a>
              .
            </p>
          </div>
        </div>
      </section>
      <section id="loyalty">
        <div className="blueprint py-32">
          <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto">
            <h2 className="jumbo pb-8">Loyalty</h2>
            <p className="lead max-w-2xl">
              Blockchain and Web3 will give user data back to the user but still
              allow artists, athletes, and sports teams to gain insight and
              reward their true fans without paying an intermediary.
            </p>
          </div>
        </div>
        <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto mt-4">
          <p className="mb-4">
            Unlocks can be a valuable tool for promoting and tracking fan
            loyalty. In today's market, it is hard to discern who is a true fan.
            A person can be a season ticket holder but not a true fan. As
            mentioned above, reselling (or scalping) is a large, profitable
            market encouraged by companies like TicketMaster, who also profit
            from the secondary. Resellers post tickets for fans to buy at a
            significant mark-up as a side business price-gauging true fans.
            Venues, sports teams, and artists also do not profit from this. The
            season ticket holder/scalper often receives perks along with their
            profitable reseller business.{" "}
          </p>
          <p className="mb-16">
            Blockchain allows you to know who is purchasing a ticket and if they
            are attending the game. The sports team will not know their name,
            address or even email to contact them. However, they can still see
            the behavior of that specific fan via their public key and how they
            use it within the stadium. They can also show appreciation to that
            fan by dropping tokens to the fan's ethereum public key to use on
            merchandise, food, or even give access to autograph signings or
            special events.
          </p>
        </div>
      </section>
      <section id="collectible">
        <div className="blueprint py-32">
          <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto">
            <h2 className="jumbo pb-8">Collectibles</h2>
            <p className="lead max-w-2xl">
              Digital collectibles in the form of NFTs have taken the world by
              storm in 2021. So it's only a matter of time before sports and
              music ditch the bobbleheads and adopt this new form of souvenir.
            </p>
          </div>
        </div>
        <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto mt-4">
          <p className="mb-4">
            Personally, as a fan, I still have all of the ticket stubs of every
            show and sporting event that I have ever attended. There are shows
            whose tickets have simple plain lines of text that describe the
            event, and then there are some beautifully designed tickets.
            Unfortunately, after marriage, my tickets are in a manilla envelope,
            and every once in a while, I will pull them out and look at them.
            Some fans put them in scrapbooks or have creative ways of showcasing
            them. Now that tickets are going digital, we can enhance this
            experience and spark nostalgia and fond memories better.
          </p>
          <p className="mb-4">
            We also can create collectibles that will last. Collectibles have
            primarily been physical and, for the most part, cheap. The cost of
            providing a free trinket for over 30,000 fans can get expensive.
            However, there's still enjoyment for receiving such pieces as
            bobbleheads. There is even a marketplace to trade, buy, and sell
            despite the quality—digital souvenirs and blockchain help to verify
            its authenticity. Digital souvenirs could be in the form of games
            that fans can play during or after the event, or animations, or
            graphic design created by a famous digital artist. I believe the
            fusion of digital, gaming, and events will become popular in the
            future.
          </p>
          <p className="mb-4">
            Sports teams are already using blockchain to validate memorabilia.
            For example, the Sacramento Kings have given out player autographed
            sneakers and jerseys marked with a tag that can be scanned and
            validated with blockchain technology. As a result, fans can easily
            verify the authenticity of the memorabilia.
          </p>
          <p className="mb-16">
            The NBA has TopShots which capture moments of a game. Fans can buy
            these "moments" with varying degrees of rarity. However, something
            yet to be seen is sharing "moments" with attendees of a game. For
            example, suppose a fan went to a game where someone breaks a record
            or makes a phenomenal play. Ethereum blockchain will allow that team
            to verify that you were in attendance and drop a Top Shots-Esque
            souvenir into your Ethereum wallet so that you can own the moment.
          </p>
          <h5 className="uppercase">Our Experiments</h5>
          <p>
            Team Zero along with teammates,{" "}
            <a href="https://www.treum.io/" target="_blank" rel="noreferrer">
              Treum
            </a>
            , the makers of{" "}
            <a href="https://eulerbeats.com/" target="_blank" rel="noreferrer">
              EulerBeats
            </a>{" "}
            ran an experiment on chance-based minting of NFTs with artist,{" "}
            <a
              href="https://jonathanrosen.com/"
              target="_blank"
              rel="noreferrer"
            >
              Jonathan Rosen
            </a>
            . Here is the use case and result.
          </p>
          <a href="../rosen">
            {/* Card ONE */}
            <div className="md:flex rounded overflow-hidden shadow-lg my-4 bg-white mb-16">
              <img
                className="flex-auto w-full overflow-hidden"
                src={rosenThumb}
                alt="Jonathan Rosen NFT"
              />
              <div className="px-6 py-4 flex-wrap">
                <div className="font-bold text-xl mb-2">
                  Jonathan Rosen Drop
                </div>
                <p className="text-gray-700 text-base">
                  Word based artist. Jonathan Rosen chance-based minting of
                  word-art NFTs.
                </p>
              </div>
            </div>
          </a>
        </div>
      </section>

      <section id="revenue">
        <div className="blueprint py-32">
          <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto">
            <h2 className="jumbo pb-8">New Revenue</h2>
            <p className="lead max-w-2xl">
              Web3 will develop new ways for musicians, athletes, and teams to
              create micro-franchises from their personal brand using a
              pay-it-backward method of smart contracts.
            </p>
          </div>
        </div>
        <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto mt-4">
          <p className="mb-16">
            With all of these possibilities for new and authenticated
            collectibles and the marketplaces that it creates for fans, this
            could create new earning models for fans. In today's models,
            artists, athletes, and sports teams may not appreciate fans making
            money exploiting an athlete or artist's celebrity and notoriety.
            However, Ethereum smart contracts can tie royalties back to the
            artist, athlete, and sports team. Every sale can trigger a
            percentage of the earnings back to the musician, athlete, or sports
            team. Ease of using smart contracts and a connection to adding value
            to a fan's favorite player, team, or artist gives more incentive to
            fans that create to recognize intellectual property laws. If a fan
            creates a successful business, smart contracts can automatically
            give percentages back to the artist, athlete, or sports team and
            still allow the fan to earn on their creation. The selling of
            unlicensed material is already happening illegally. Smart contracts
            can create mutually beneficial and endless revenue streams. It forms
            a new type of franchise model for fans endorsing the brand of an
            artist, athlete, and sports team that they are passionate about
            serving. This franchise idea may not be beneficial for the Lebron
            James of the world, but it could create lucrative cult followings
            for that 7th man off the bench in basketball or middle reliever in
            baseball. A franchising model could also be attractive for minor
            leaguers in the major sports leagues, independent leagues, xSports,
            and the rising fanbases for eSports.
          </p>

          <h5 className="uppercase">Our Experiments</h5>
          <p>
            Before we were Team Zero, we were{" "}
            <a
              href="https://mesh.xyz/web3studio/"
              target="_blank"
              rel="noreferrer"
            >
              Web3Studio
            </a>{" "}
            doing similar experiments as we do now. We attempted to attack the
            Digital Rights Management problem with the pay-it-backward method
            smart contract. This was showcased at The Blockchain House at SXSW
            2019. Here is the use case and result.
          </p>
          <a href="../bootleg">
            {/* Card ONE */}
            <div className="md:flex rounded overflow-hidden shadow-lg my-4 bg-white mb-16">
              <img
                className="flex-auto w-full overflow-hidden"
                src={bootlegThumb}
                alt="Bootleg project for SXSW"
              />
              <div className="px-6 py-4 flex-wrap">
                <div className="font-bold text-xl mb-2">Bootleg</div>
                <p className="text-gray-700 text-base">
                  Concert footage as an unlock for the NFT holder. Unique shared
                  royalty model for previous NFT owners.
                </p>
              </div>
            </div>
          </a>
        </div>
      </section>
      <div className="lg:w-1/2 m-1 lg:mx-auto">
        <h2 className="m-2">Recent Articles</h2>
        <ArticlesList posts={posts} />
      </div>

      <div
        id="form"
        className="m-4 lg:mr-20 grid grid-cols-1 lg:grid-cols-2 gap-4"
      >
        <div className="m-auto order-last mt-6 lg:order-first lg:mr-10 pb-4">
          <FeedbackCard location="projects/nftix" />
        </div>
        <div className="mt-4 ml-1 mr-10">
          <AboutUs />
        </div>
      </div>
    </Layout>
  )
}

export default NftixProject

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { project: { eq: "NFTix" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          author
          tags
          project
        }
      }
    }
  }
`
